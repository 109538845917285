<template>
  <div class="CloudBG">
    <div class="cloud-btn">
      <!-- <div :class="i==idx?'cloud-select':''" v-for="(item,i) in data" :key="i" @click="handlecould(i)">{{item}}</div> -->
      <div :class="0==idx?'cloud-select':''" @click="handlecould(0)">{{data[0]}}</div>
      <!-- <div :class="1==idx?'cloud-select':''" @click="handlecould1(1)">{{data[1]}}</div> -->
      <div :class="2==idx?'cloud-select':''" @click="handlecould2(2)">{{data[2]}}</div>
      <div :class="3==idx?'cloud-select':''" @click="handlecould3(3)">{{data[3]}}</div>

    </div>




<el-dialog title="上传文件" :visible.sync="isshow"  >
 <el-row>
  <el-col :span="8"  >
    <div class="UPBG">
<el-upload
  drag
  action="https://jsonplaceholder.typicode.com/posts/"
  multiple>
  <i class="el-icon-plus" style="color:#FC4353;font-size:50px"></i>
</el-upload>

    </div>
  <p style="text-align: center;margin-top:20px">点击或拖拽此处上传文件</p>


    </el-col>



     <el-col :span="8"  >
    <div class="UPBG">
      <el-upload
  drag
  action="https://jsonplaceholder.typicode.com/posts/"
  multiple>
  <i class="el-icon-video-play" style="color:#FC4353;font-size:50px"></i>
</el-upload>

    </div>
  <p style="text-align: center;margin-top:20px">点击或拖拽此处上传视频</p>


    </el-col>



     <el-col :span="8"  >
    <div class="UPBG">
      <el-upload
        drag
        action="https://jsonplaceholder.typicode.com/posts/"
        multiple>
        <i class="el-icon-folder-opened" style="color:#FC4353;font-size:50px"></i>
      </el-upload>

    </div>
       <p style="text-align: center;margin-top:20px">点击或拖拽此处上传文件</p>
    </el-col>
</el-row>

 <div class="block" v-for="fit in fits" :key="fit">
    <!-- <span class="demonstration">{{ fit }}</span> -->
    <el-image
    v-if="false"
      style="width: 100px; height: 100px"
      :src="url"
      :fit="fit"></el-image>
  </div>
  <div slot="footer" class="dialog-footer">
    <el-button @click="isshow = false">取 消</el-button>
    <el-button type="primary" @click="isshow = false">确 定</el-button>
  </div>
</el-dialog>



    <!-- <div class="picker" @click.self="handlehidden()" v-if="isshow">
      <div class="pic-box">
        <div class="title">
          <p>上传照片</p>
          <p @click="handleclosecha">X</p>
        </div>
        <div class="bigup">
          <div class="bone">
            <div class="three">
              <i class="el-icon-plus"></i>
            </div>
            <p>点击或拖拽至此上传照片</p>
          </div>
          <div class="bone">
            <div class="three">
              <i class="el-icon-video-play"></i>
            </div>
            <p>点击或拖拽至此上传视频</p>
          </div>
          <div class="bone">
            <div class="three">
              <i class="el-icon-folder-opened"></i>
            </div>
            <p>点击或拖拽至此上传文件</p>
          </div>
        </div>
        <div class="show">
          <p>缩略图</p>
          <div>
            <img v-for="(item,i) in imglist" :key="i" :src="item.img" alt="">
          </div>
        </div>
        <div class="save">保存</div>
      </div>
    </div> -->
    <!-- <div class="up" @click="handleup()" v-if="istext && idx==0 || idx==1">
      <i class="el-icon-upload2"></i>
      <p>上传文件</p>
    </div> -->
    <div class="cloud" v-if="idx==0" @click="handleclick()">
      <p>已用云盘30G（云盘备份，重要数据不在丢失）</p>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isshow: false,
        istext: true,
        imageUrl: '',
        idx: 0,
        data: ['容量概览', '云盘设置', '点击扩容','文件管理'],
        imglist: [{
            img: '../../../assets/img/logo.png'
          },
          {
            img: '../../../assets/img/logo.png'
          },
          {
            img: '../../../assets/img/logo.png'
          },
          {
            img: '../../../assets/img/logo.png'
          },
          {
            img: '../../../assets/img/logo.png'
          },
          {
            img: '../../../assets/img/logo.png'
          }
        ],
        fits: ['fill' ],
        url: ''
      }
    },
    methods: {
      handleclick() {
        this.$router.push({
          path: '/EnterpriseConfirm'
        })
      },
      handlecould(i) {
        this.idx = i
        this.$router.push({
          path: '/cloudStorage'
        })
      },
      handlecould1(i) {
        this.idx = i
        this.$router.push({
          path: '/CloudDiskSettings'
        })
      },
      handlecould2(i) {
        this.idx = i
        // this.$router.push('/EnterpriseConfirm') 
        this.$router.push('/ClickExpand') 

      },
      handlecould3(i) {
        this.idx = i
        this.$router.push('/wenjiangl')
      },
      handleup() {
        this.isshow = true
        this.idx = 1
        // this.istext = false
        this.$router.push({
          path: '/CloudDiskSettings'
        })
      },
      handlehidden() {
        this.isshow = false
        // this.istext = true
      },
      handleclosecha() {
        this.isshow = false
      }
    },
    mounted() {
      try {
        this.$router.push({
          path: '/cloudStorage'
        })
      } catch (error) {
        
      }


    }
  }

</script>

<style scoped lang='scss'>
  .CloudBG {
    margin-top: 20px;
    background: #ffffff;
    border-radius: 10px;
  }

  .cloud {

    p {
      font-size: 16px;
      color: gainsboro;
      position: absolute;
      bottom: 160px;
      right: 100px;
      cursor: pointer;
    }
  }

  .save {
    width: 100px;
    height: 40px;
    background-color: red;
    color: white;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    border-radius: 10px;
    margin: 50px auto;
  }

  .show {
    p {
      font-size: 16px;
      margin-top: 30px;
      margin-left: 30px;
    }

    div {
      display: flex;
      justify-content: space-around;
    }

    img {
      margin-top: 30px;
      width: 80px;
      height: 80px;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .bigup {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;

    .bone {
      text-align: center;
      font-size: 16px;
      color: #ff6c71;

      .three {
        width: 200px;
        height: 200px;
        border: 1px dashed #ff6c71;

        i {
          font-size: 30px;
          line-height: 200px;
        }
      }

      p {
        margin-top: 10px;
      }
    }
  }

  .up {
    color: gainsboro;
    cursor: pointer;
    font-size: 30px;
    display: flex;
    position: absolute;
    right: 60px;
    top: 260px;

    p {
      margin-left: 15px;
    }
  }
.UPBG{
  width: 200px;
  height: 200px;
  border: 3px dashed #FC4353;
  border-radius: 5px;
  text-align: center;
  line-height: 200px;
  vertical-align: middle;
  
}
  .picker {
    width: 100vw;
    height: 100vh;
    // background-color: rgba($color: #000000, $alpha: 0.3);
    position: fixed;
    top: 100px;
    bottom: 0;

    .pic-box {
      width: 700px;
      max-height: 600px;
      background-color: #ffecf0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .cloud-btn {
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;

    .cloud-select {
      background-color: #ff404e;
      color: white;
      border: none;
    }

    div {
      width: 80px;
      height: 30px;
      margin-left: 100px;
      font-size: 12px;
      line-height: 30px;
      text-align: center;
      color: #ff404e;
      border-radius: 10px;
      border: 1px solid #feb9be;
    }
  }

</style>
<style>
.UPBG .el-upload-dragger{
  width: 100%;
  border: none;
      /* height: 200px; */
    /* line-height: 200px; */
}

.CloudBG .el-dialog{
  width: 700px;
}
</style>